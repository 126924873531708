.more-info {
	color: $white;
	h2 {
		font-size: 40px;
		text-align: center;
	}
	p {
		font-size: 20px;
		text-align: center;
	}
	.btn-light-blue {
		text-align: center;
	}
}