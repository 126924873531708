.mobile-info {
	background: $light-gray;
	h2 {
		font-size: 32px;
		font-size: 32px;
    	line-height: 40px;
	}
	h3 {
		font-size: 15px;
		text-align: center;
	}

	p {
		font-size: 16px;
	}

	img {
		max-width: 250px;
	}
}