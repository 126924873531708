.desktop-info {
	background: $teal;
	color: $white;
	h2 {
		font-size: 40px;
		text-align: center;
	}
	p {
		font-size: 24px;
		line-height: 30px;
	}
	img {
		max-width: 570px;
    	margin: 0 auto;
	}
	ul {
		li {
			margin-left: 30px;
			font-size: 16px;
		    line-height: 33px;
            list-style: none;
		    &:before {
                background: url("/images/home/check.png") no-repeat;
                content:"";
                width: 20px;
                height: 20px;
                position: absolute;
                left: 15px;
                margin-top: 7px;
			}
		}
	}

}